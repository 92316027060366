import { fetcher } from '../fetcher';

const NOTIFICATION_CAMPAIGNS_ENDPOINT = '/notification-campaigns';

export type ManyNotificationCampaigns = (DefaultNotificationForm & {
  is_active: boolean;
  kind_type: 'default' | 'form' | 'visit';
})[];

export type ManyNotificationTemplates = Omit<
  ManyNotificationCampaigns[number],
  'is_active'
>[];

export type AddManyNotificationCampaigns = {
  notification_campaigns: ManyNotificationCampaigns;
};

export type AddManyTemplateNotificationCampaigns = {
  notification_templates: ManyNotificationTemplates;
};

export const formatNotificationCampaigns = (
  payload: DefaultNotificationForm[],
) => {
  return payload
    .map((form) => {
      if (form.start_time) {
        return {
          ...form,
          start_time: `${formatDateToHi(form.start_time)}:00`,
        };
      }
      return form;
    })
    .map((form) => {
      if (form.start_date) {
        return {
          ...form,
          start_date: formatDateToYYYYMMDD(new Date(form.start_date)),
        };
      }
      return form;
    })
    .map((form) => {
      if (form.end_date) {
        return {
          ...form,
          end_date: formatDateToYYYYMMDD(new Date(form.end_date)),
        };
      }
      return form;
    })
    .map((form) => {
      if (form.recurring_calendar_days) {
        return {
          ...form,
          recurring_calendar_days: form.recurring_calendar_days.map((day) =>
            formatDateToYYYYMMDD(new Date(day)),
          ),
        };
      }
      return form;
    });
};

export const notificationCampaignsService = {
  storeNotificationCampaign: async (payload: NotificationCampaign) => {
    return await fetcher(`${NOTIFICATION_CAMPAIGNS_ENDPOINT}`, {
      method: 'POST',
      body: payload,
    });
  },

  storeNotificationCampaigns: async (payload: AddManyNotificationCampaigns) => {
    const body = {
      notification_campaigns: payload.notification_campaigns.map((form) => {
        return formatNotificationCampaigns([form])[0];
      }),
    };

    return await fetcher(`${NOTIFICATION_CAMPAIGNS_ENDPOINT}/many`, {
      method: 'POST',
      body,
    });
  },

  storeTemplateNotificationCampaigns: async (
    payload: AddManyTemplateNotificationCampaigns,
  ) => {
    const body = {
      notification_templates: payload.notification_templates.map((form) => {
        return formatNotificationCampaigns([form])[0];
      }),
    };

    return await fetcher(`/notification-templates/many`, {
      method: 'POST',
      body,
    });
  },

  getNotificationCampaigns: async (
    isActive: boolean,
    search: string,
    page: number,
    per_page: number,
  ) => {
    return await fetcher(
      `${NOTIFICATION_CAMPAIGNS_ENDPOINT}?filters[active]=${isActive ? 1 : 0}&search=${search}&page=${page + 1}&per_page=${per_page}`,
      {
        method: 'GET',
        meta: true,
        schema: metaNotificationCampaignSchema,
      },
    );
  },

  editNotificationCampaign: async (
    payload: DefaultNotificationForm,
    notificationCampaign_id: number,
  ) => {
    const body = formatNotificationCampaigns([payload])[0];

    return await fetcher(
      `${NOTIFICATION_CAMPAIGNS_ENDPOINT}/${notificationCampaign_id}`,
      {
        method: 'PATCH',
        body,
      },
    );
  },

  deleteNotificationCampaign: async (notificationCampaign_id: number) => {
    return await fetcher(
      `${NOTIFICATION_CAMPAIGNS_ENDPOINT}/${notificationCampaign_id}`,
      {
        method: 'DELETE',
      },
    );
  },
};
