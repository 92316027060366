import { fetcher } from '../fetcher';
import { formatNotificationCampaigns } from './notificationCampaignsService';

const NOTIFICATION_TEMPLATES_ENDPOINT = '/notification-templates';

export const notificationTemplatesService = {
  getNotificationTemplates: async (
    search: string,
    page: number,
    per_page: number,
  ) => {
    return await fetcher(
      `${NOTIFICATION_TEMPLATES_ENDPOINT}?search=${search}&page=${page + 1}&per_page=${per_page}`,
      {
        method: 'GET',
        meta: true,
        schema: metaTemplateNotificationCampaignSchema,
      },
    );
  },

  editNotificationCampaign: async (
    payload: DefaultNotificationForm,
    notificationCampaign_id: number,
  ) => {
    const body = formatNotificationCampaigns([payload])[0];

    return await fetcher(
      `${NOTIFICATION_TEMPLATES_ENDPOINT}/${notificationCampaign_id}`,
      {
        method: 'PATCH',
        body,
      },
    );
  },

  deleteNotificationTemplate: async (notificationTemplate_id: number) => {
    return await fetcher(
      `${NOTIFICATION_TEMPLATES_ENDPOINT}/${notificationTemplate_id}`,
      {
        method: 'DELETE',
      },
    );
  },
};
