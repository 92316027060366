<script setup lang="ts">
const { form, errors, validate, resetForm } =
  useDefaultNotificationCampaignForm();

defineExpose({
  form,
  validate,
  errors,
  resetForm,
});
</script>

<template>
  <PrimeAccordionHeader
    class="border text-gray-iron-900"
    :class="{
      '!border-red-600': Object.keys(errors).length,
    }"
  >
    <DefaultInput
      class="mr-4"
      v-model="form.designation"
      :error="errors.designation"
      @click.stop
    >
      Oznaczenie
    </DefaultInput>

    <slot name="close" />
  </PrimeAccordionHeader>

  <PrimeAccordionContent class="bg-gray-iron-100">
    <div class="space-y-4">
      <slot />

      <NotificationReccuringTypeSelector v-model:form="form" :errors="errors" />

      <NotificationMethodPicker v-model:form="form" :errors="errors" />

      <NotificationUsersSelector v-model:form="form" :errors="errors" />

      <DefaultInput v-model="form.title" :error="errors.title">
        Tytuł
      </DefaultInput>

      <DefaultTextArea v-model="form.body" :error="errors.body">
        Treść
      </DefaultTextArea>
    </div>
  </PrimeAccordionContent>
</template>
