<script setup lang="ts">
const { form, errors, validate, resetForm } =
  useVisitNotificationCampaignForm();

defineExpose({
  form,
  validate,
  errors,
  resetForm,
});

const { activeSchemats } = useSchemats();
</script>

<template>
  <PrimeAccordionHeader
    class="border text-gray-iron-900"
    :class="{
      'border-red-600': Object.keys(errors).length,
    }"
  >
    <DefaultInput
      class="mr-4"
      v-model="form.designation"
      :error="errors.designation"
      @click.stop
    >
      Oznaczenie
    </DefaultInput>

    <slot name="close" />
  </PrimeAccordionHeader>

  <PrimeAccordionContent class="bg-gray-iron-100">
    <div class="space-y-4">
      <slot />

      <NotificationMethodPicker v-model:form="form" :errors="errors" />

      <DefaultSelect
        v-model="form.schemat_id"
        :options="activeSchemats"
        optionValue="id"
        optionLabel="name"
        filter
        placeholder="Wybierz z listy"
        :error="errors.schemat_id"
      >
        Wybierz schemat
      </DefaultSelect>

      <DefaultInput v-model="form.title" :error="errors.title">
        Tytuł
      </DefaultInput>

      <DefaultTextArea v-model="form.body" :error="errors.body">
        Treść
      </DefaultTextArea>

      <div class="flex items-center gap-2">
        <div>
          <DefaultInputNumber
            class="[&__.p-inputnumber]:max-w-40"
            v-model="form.quantity_days"
            showButtons
            buttonLayout="horizontal"
            :step="1"
            :minFractionDigits="0"
            suffix="dni"
            fluid
            :min="1"
            inputClass="text-center text-sm"
            :error="errors.quantity_days"
          >
            Wyślij przypomnienie do pacjenta o nadchodzącej wizycie
          </DefaultInputNumber>
        </div>

        <div class="-ml-44 mt-3">przed</div>
      </div>
    </div>
  </PrimeAccordionContent>
</template>
